.bar-height {
    height: 25px;
}

.row {
    display:  flex;
}



.col-2 {
    flex-grow: 2;
}

.col-3 {
    flex-grow: 3;
}

.col-4 {
    flex-grow: 4;
}

.col-5 {
    flex-grow: 5;
}

.col-6 {
    flex-grow: 6;
}

.smoothLeftSide {
    border-radius: 3px 0 0 3px ;
}
.smoothRightSide {
    border-radius: 0 3px 3px 0;
}

.bg-white {
    background-color: white;
}

.text-black {
    color: rgb(0, 0, 0);
}
.left-margin-20  {    
    margin-left: 20px;
}
.top-margin-5 {
    margin-top: 2px;
}

.bg-lighterBlue {
    background-color: rgb(0, 201, 201);
}
.bg-lightBlue {
    background-color: rgb(0, 150, 150);

}
.keySize {
    width: 10px;
}
.keyLockSize {
    width: 15px;
    margin-right: 8px;
}
.WhiteLineStyle {
    background-color: white;
    height: 30px;
    width: 2px;
}
.WhiteLinePos1 {
    position: absolute;
    top: -24px;
    left: 4px;
}
.WhiteLinePos2 {
    position: absolute;
    top: -24px;
    right: 4px;
}

.keyPos {
    position: absolute;
    bottom: -13px;
    left: -3px;
}
.left-margin-5 {
    margin-left: 10px;
}
.noSize {
    height: 0px;
    width: 0px;
    overflow: visible;
}
.text-blue {
    color: rgb(0, 173, 173);
}

.fontSize10 {
    font-size: 10px;
}
.fontSize15 {
    font-size: 15px;
}
.text-start {
    text-align: start;
}