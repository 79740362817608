@import url(https://fonts.googleapis.com/css?family=Roboto);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#Checklist {
    position: fixed;
    right: 20px;
    bottom: 30px;    
    font-family: 'Roboto', sans-serif;
}

#GetStarted {
    border-radius: 15px;
    width: 150px;
    height: 35px;
    text-align: center;
    display: flex;
    justify-content: center;
    font-family: ubuntu, sans-serif;
    font-size: 14px;
}

.arrow-size {
    width: 8px;
    height: 5px;
}

.bg-light_purple-hover:hover {
    background-color: rgb(86, 39, 243);
    cursor: pointer;
}
.bg-light_blue-hover:hover {
    background-color: rgb(51, 67, 82);
    cursor: pointer;
}

#CheckList {
    width: 380px;
    height: 330px;
}

.top-padding-10 {
    padding-top: 10px;
}
.div_buttonSize {
    width: 140px;
    height: 30px;
    padding-top: 5px;
}

.max-width-280 {
    max-width: 280px;
}
.max-width-290 {
    max-width: 290px;
}

.completionImageSize {
    width: 85px;
}

.bottom-margin-5 {
    margin-bottom: 5px;
}

a {
    color: inherit;
    text-decoration: inherit;
}

.fontSize-12 {
    font-size: 12px;
}

.right-margin-3 {
    margin-right: 3px;
}
.bar-height {
    height: 25px;
}

.row {
    display:  flex;
}



.col-2 {
    flex-grow: 2;
}

.col-3 {
    flex-grow: 3;
}

.col-4 {
    flex-grow: 4;
}

.col-5 {
    flex-grow: 5;
}

.col-6 {
    flex-grow: 6;
}

.smoothLeftSide {
    border-radius: 3px 0 0 3px ;
}
.smoothRightSide {
    border-radius: 0 3px 3px 0;
}

.bg-white {
    background-color: white;
}

.text-black {
    color: rgb(0, 0, 0);
}
.left-margin-20  {    
    margin-left: 20px;
}
.top-margin-5 {
    margin-top: 2px;
}

.bg-lighterBlue {
    background-color: rgb(0, 201, 201);
}
.bg-lightBlue {
    background-color: rgb(0, 150, 150);

}
.keySize {
    width: 10px;
}
.keyLockSize {
    width: 15px;
    margin-right: 8px;
}
.WhiteLineStyle {
    background-color: white;
    height: 30px;
    width: 2px;
}
.WhiteLinePos1 {
    position: absolute;
    top: -24px;
    left: 4px;
}
.WhiteLinePos2 {
    position: absolute;
    top: -24px;
    right: 4px;
}

.keyPos {
    position: absolute;
    bottom: -13px;
    left: -3px;
}
.left-margin-5 {
    margin-left: 10px;
}
.noSize {
    height: 0px;
    width: 0px;
    overflow: visible;
}
.text-blue {
    color: rgb(0, 173, 173);
}

.fontSize10 {
    font-size: 10px;
}
.fontSize15 {
    font-size: 15px;
}
.text-start {
    text-align: start;
}
#profileImage {
  border-radius: 100%;
  width: 40x;
  height: 40px;
}

.selected-btn {
  border-bottom: 4px solid rgb(134, 69, 192) !important;
}

#DivButtonsContainer div {
  height: 70px;
  text-align: center;
  justify-content: center;
  justify-items: center;
  border-bottom: 4px solid rgb(36, 15, 103);
}
#DivButtonsContainer a {
  align-self: center;
  color: inherit;
  text-decoration: inherit;
}

#DivButtonsContainer div:hover {
  border-bottom: 4px solid rgb(112, 30, 184);
  cursor: pointer;
}


.bg-dark {
  background-color: rgb(37, 37, 37);
  min-height: 100vh;
}

.flex-end {
    display: flex;
    justify-content: end;
    align-items: center;
  }
  
  .side-margin-5 {
    margin: 0 5px;
  }

.inFront {
  z-index: 10;
}

  .side-margin-15 {
    margin: 0 15px;
  }
  .side-margin-25 {
    margin: 0 15px;
  }

  .flex {
    display: flex;
  }
  .bg-purple {
    background-color: rgb(36, 15, 103);
  }

  .bg-blue {
    background-color: rgb(43, 55, 66);
  }

  .box-height-80 {
    height: 80px;
    width: 100%;
  }
  
  .relative {
    position: relative;
  }
  
  .fixed {
    position: fixed;
  }

  
  .justify-center{
    justify-content: center;
  }

  .justify-start{
    justify-content: start;
  }

  .justify-between{    
    justify-content: space-between;
  }
  .flex-between{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .text-white {
    color: white;
  }

  .self-center {
      align-self: center;
  }

  .bg-light-green {
    background-color: rgb(46, 204, 113);
  }

.pointer-cursor:hover {  
  cursor: pointer;
}

.fontSize-20 {
  font-size: 20px;
}
.fontSize-5 {
  font-size: 5px;
}

.checkboxSize {
  height: 0px;
  border-radius: 20px !important;
  zoom: 0.7;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

